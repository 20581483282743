import { Roles } from '../roles'
import { lazyImport } from '../../hooks/LazyImport'
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone'
import SwipeUpTwoToneIcon from '@mui/icons-material/SwipeUpTwoTone'
import CachedIcon from '@mui/icons-material/Cached'
import CancelIcon from '@mui/icons-material/Cancel'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'

const { CustomerRegistrationPage } = lazyImport(
  () => import('src/pages/dashboard/CustomerRegistration'),
  'CustomerRegistrationPage'
)

const { SearchAccountPage } = lazyImport(
  () => import('src/pages/dashboard/SearchAccount'),
  'SearchAccountPage'
)

const { CancelAccountPage } = lazyImport(
  () => import('src/pages/dashboard/CancelAccount'),
  'CancelAccountPage'
)

const { GetClientByDomainPage } = lazyImport(
  () => import('src/pages/dashboard/GetClientByDomainName'),
  'GetClientByDomainPage'
)

const { RevokeCancellationPage } = lazyImport(
  () => import('src/pages/dashboard/RevokeCancellation'),
  'RevokeCancellationPage'
)

const { AccountReactivation } = lazyImport(
  () => import('src/pages/dashboard/AccountReactivation'),
  'AccountReactivation'
)

const { ExtendTrialPage } = lazyImport(
  () => import('src/pages/dashboard/ExtendTrial'),
  'ExtendTrialPage'
)

const { AddBulkDomainsPage } = lazyImport(
  () => import('src/pages/dashboard/AddBulkDomains'),
  'AddBulkDomainsPage'
)

export const AccountRoutes = [
  {
    name: 'Search account',
    icon: <PersonSearchTwoToneIcon />,
    route: '/dashboard/search-account-by-account-id',
    path: 'search-account-by-account-id/*',
    element: <SearchAccountPage />,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['marketing'],
      Roles['sales'],
    ],
  },
  {
    name: 'Get account by domain',
    icon: <TravelExploreTwoToneIcon />,
    route: '/dashboard/get-client-by-domain',
    path: 'get-client-by-domain/*',
    element: <GetClientByDomainPage />,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['marketing'],
      Roles['sales'],
    ],
  },
  {
    name: 'Cancel account',
    icon: <CancelIcon />,
    route: '/dashboard/cancel-account',
    path: 'cancel-account/*',
    element: <CancelAccountPage />,
    permissions: [Roles['cheq-admin-t2'], Roles['team-leader'], Roles['cheq-admin-t4']],
  },
  {
    name: 'Account Reactivation',
    icon: <CachedIcon />,
    route: '/dashboard/account-reactivation',
    path: 'account-reactivation/*',
    element: <AccountReactivation />,
    permissions: [Roles['cheq-admin-t2'], Roles['cheq-admin-t4'], Roles['team-leader']],
  },
  {
    name: 'Revoke cancellation',
    icon: <SwipeUpTwoToneIcon />,
    route: '/dashboard/revoke-cancellation',
    path: 'revoke-cancellation/*',
    element: <RevokeCancellationPage />,
    permissions: [Roles['cheq-admin-t2'], Roles['team-leader'], Roles['cheq-admin-t4']],
  },
  {
    name: 'Extend trial',
    icon: <MoreTimeIcon />,
    route: '/dashboard/extend-trial',
    path: 'extend-trial/*',
    element: <ExtendTrialPage />,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['marketing'],
      Roles['sales'],
    ],
  },
  {
    name: 'Create new account',
    icon: <PersonAddAltIcon />,
    route: '/dashboard/customer-registration',
    path: 'customer-registration/*',
    element: <CustomerRegistrationPage />,
    permissions: [Roles['cheq-admin-t4'], Roles['team-leader'], Roles['sales']],
  },
  {
    name: 'Add domains in bulk',
    icon: <CreateNewFolderIcon />,
    route: '/dashboard/add-bulk-domains',
    path: 'add-bulk-domains/*',
    element: <AddBulkDomainsPage />,
    permissions: [Roles['cheq-admin-t4'], Roles['team-leader'], Roles['cheq-admin-t2']],
  },
]
